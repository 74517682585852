import axios from 'axios'

// IP address of server

// staging
// export const SERVER_IP = 'https://clam.wellfit.c66.me'
// production
// export const SERVER_IP = 'http://0.0.0.0:3000'
export const SERVER_IP = 'https://subscribe.thewellfitclub.com'
// Port number
export const SERVER_PORT = 8080;

// server connection
export const SERVER_URL = SERVER_IP+'/api/v1/';


export default () => {
  return axios.create({
    baseURL:SERVER_URL+'',
    headers:{
      'Content-Type':'application/json',
      'Accept': 'application/json',
      // 'Authorization':sessionStorage.getItem("token")
    }
  })
}
