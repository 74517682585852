<template>
  <div>
    <div class="">
      <div class="main-det">
        <Move v-if="this.$route.params.cat == 'move'"></Move>
        <Eat v-else-if="this.$route.params.cat == 'eat'"></Eat>
        <Drink v-else-if="this.$route.params.cat=='drink'"></Drink>
        <Feel v-else-if="this.$route.params.cat=='feel'"></Feel>
        <Live v-else-if="this.$route.params.cat=='live'"></Live>
        <Rest v-else-if="this.$route.params.cat=='rest'"></Rest>
        <Sleep v-else-if="this.$route.params.cat=='sleep'"></Sleep>
        <Promo v-else-if="this.$route.params.cat == 'promotional'"></Promo>
        <div v-else>
          <div class="pagination pagination1"></div>
          <pre><code class="hljs javascript" data-script="flicking1"></code></pre>
          <div class="today" v-if="week.length > 0">
            <div class="col-sm-12 p-0">
              <div class="row m-0">
                <h5>Classes Happening This Week</h5>
                <button
                  :disabled="week.length > 0 && sT == 0"
                  class="btn-link prev btn"
                  @click="prevToday()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  :disabled="week.length > 0 && week.length <= sT + 1"
                  class="btn-link btn"
                  @click="nextToday()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <!-- <div v-if="week.length==0">
              <h3 class="text-center p-5">Nothing is Classes Happening This Week</h3>
            </div> -->
            <div class="row erow m-0 w-100">
              <div
                v-for="(ins, index) in week.slice(sT)"
                :key="'ht' + index"
                class="htoday col-lg-4 col-md-6"
              >
                <router-link
                  v-if="
                    ins.content_host_type == 'VimeoVideo' ||
                      ins.content_host_type == 'WellFitVideo'
                  "
                  :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                >
                  <div class="">
                    <img
                      class="w-100"
                      :src="
                        ins.thumbnail_urls != null
                          ? ins.thumbnail_urls.large
                          : ''
                      "
                    />
                    <p class="d-inline-block mb-0">{{ ins.title }}</p>
                    <!-- <span class="live" v-if="ins.scheme == 'live'">
                      <img class="live-md" src="../../../assets/live.png"
                    /></span> -->
                    <p class="d-inline-block mb-1 sesstime">
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                  </div>
                </router-link>
                <a v-else target="_blank" :href="ins.content_url">
                  <div class="">
                    <img
                      class="w-100"
                      :src="
                        ins.thumbnail_urls != null
                          ? ins.thumbnail_urls.large
                          : ''
                      "
                    />
                    <p class="d-inline-block mb-0">{{ ins.title }}</p>
                    <!-- <span class="live" v-if="ins.scheme == 'live'">
                      <img class="live-md" src="../../../assets/live.png"
                    /></span> -->
                    <p class="d-inline-block mb-1 sesstime">
                      {{
                        moment(ins.start_at)
                          .tz(moment.tz.guess(true))
                          .format("dddd MMMM DD, YYYY h:mmA z")
                      }}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="workouts" v-if="categories.length > 0">
            <div class="col-sm-12 p-0">
              <div class="row m-0">
                <h5>Recorded Workout Sessions</h5>

                <button
                  :disabled="categories.length > 0 && sW == 0"
                  class="btn-link btn prev"
                  @click="prevWork()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  :disabled="
                    categories.length > 0 && categories.length <= sW + 1
                  "
                  class="btn-link btn"
                  @click="nextWork()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="row m-0 erow w-100">
              <router-link
                class="col-lg-2 pl-0"
                :to="{ name: 'Episodes', params: { id: ins.id } }"
                v-for="(ins, index) in categories.slice(sW)"
                :key="'category' + index"
              >
                <div class="workout">
                  <img
                    :src="
                      ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''
                    "
                  />
                  <!-- <p >{{ins.name}}</p> -->
                </div>
              </router-link>
            </div>
          </div>
          <div class="instructors" v-if="presenters.length > 0">
            <div class="col-sm-12 p-0 mb-2">
              <div class="row m-0">
                <h5>Instructors</h5>

                <button
                  :disabled="presenters.length > 0 && sI == 0"
                  class="btn-link prev btn"
                  @click="prevIns()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  :disabled="
                    presenters.length > 0 && presenters.length <= sI + 1
                  "
                  class="btn-link btn"
                  @click="nextIns()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="row erow m-0 w-100">
              <router-link
                class="col-lg-2 pl-0"
                :to="{ name: 'ShowInstructor', params: { id: ins.id } }"
                v-for="(ins, index) in presenters.slice(sI)"
                :key="'instructor' + index"
              >
                <div class="instructor">
                  <img
                    :src="ins.avatar_urls != null ? ins.avatar_urls.large : ''"
                  />
                  <p class="text-center mt-2">{{ ins.name }}</p>
                </div>
              </router-link>
            </div>
          </div>
          <div class="ebooks" v-if="books.length > 0">
            <div class="col-sm-12 p-0">
              <div class="row m-0">
                <h5>Downloads & Links</h5>
                <button
                  :disabled="books.length > 0 && sB == 0"
                  class="btn-link btn prev"
                  @click="prevBook()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  :disabled="books.length > 0 && books.length <= sB + 1"
                  class="btn-link btn"
                  @click="nextBook()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="row erow m-0">
              <a
                target="_blank"
                :href="book.content_url"
                class="eblk col-lg-3"
                v-for="(book, index) in books.slice(sB)"
                :key="'book' + index"
              >
                <div>
                  <div class="row arow m-0">
                    <img
                      :src="
                        book.thumbnail_urls ? book.thumbnail_urls.large : ''
                      "
                    />
                    <h4 class="mb-0 ml-3">
                      {{ book.title }}
                      <p>{{ book.author }}</p>
                    </h4>
                    <!-- <i v-if="book.title==1" @click="addBook(book.id)" class="far fa-bookmark"></i>
                   <i v-else @click="removeBook(book.id)" class="fas fa-bookmark"></i> -->
                    <font-awesome-icon
                      class="float-right"
                      v-if="book.bookmarked == false"
                      v-on:click.prevent="
                        addBook(book.id);
                        ga(
                          'send',
                          'Save/Download Workout',
                          [Button],
                          [Click],
                          [ins.title],
                          [Saved]
                        );
                      "
                      :icon="['far', 'bookmark']"
                    />
                    <font-awesome-icon
                      class="float-right"
                      v-else
                      v-on:click.prevent="removeBook(book.id)"
                      :icon="['fas', 'bookmark']"
                    />
                  </div>
                  <p>{{ book.description }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    // import custom from '../../javascript/custom'
    import Move from "./move";
    import Eat from "./eat";
    import Drink from './drink';
    import Feel from './feel';
    import Live from './live';
    import Rest from './rest';
    import Sleep from './sleep';
    import Promo from "./promo";

    import UserService from "../../../services/userService";
    import axios from "axios";
    import moment from "moment";
    import router from "@/router";
    // import { Component, Vue } from "vue-property-decorator";

    export default {
        name: "DashboardPage",
        data() {
            return {
                page: "",
                sB: 0,
                sI: 0,
                sT: 0,
                sW: 0,
                items: [],
                presenters: [],
                sessions: [],
                books: [],
                week: [],
                categories: [],
                plan: {},
            };
        },
        created() {
            this.id = this.$session.get("user").id;
        },
        mounted() {
            this.getProfile();
        },
        computed: {},
        components: {
          Move,
          Eat,
          Promo,
          Drink,
          Feel,
          Live,
          Sleep,
          Rest,
        },
        methods: {
            nextBook() {
                this.sB += 1;
            },
            prevBook() {
                this.sB -= 1;
            },
            nextIns() {
                this.sI += 1;
            },
            prevIns() {
                this.sI -= 1;
            },
            nextToday() {
                this.sT += 1;
            },
            prevToday() {
                this.sT -= 1;
            },
            nextWork() {
                this.sW += 1;
            },
            prevWork() {
                this.sW -= 1;
            },

            async getProfile() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getProfile(this.id);
                    if (response.data.success == true) {
                        this.plan = response.data.data.profile.current_subscription;
                        if (this.plan != null) {
                            this.$session.set("subscription", true);
                            this.getPresenters();
                            this.getBooks();
                            this.getWeek();
                            this.getCate();
                        } else {
                            this.$session.set("subscription", false);
                            router.push({
                                path: "/customer/dashboard",
                            });
                            //  this.getWeek();
                        }
                        // this.user.profile.avatar = response.data.data.profile.avatar.large
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },

            async getPresenters() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getPresenters();
                    if (response.data.success == true) {
                        this.presenters = response.data.data;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getCate() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getPrograms();
                    if (response.data.success == true) {
                        this.categories = response.data.data;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getWeek() {
                var start = moment()
                    .tz("America/New_York")
                    .subtract(2, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");
                var end = moment()
                    .tz("America/New_York")
                    .add(6, "day")
                    .format("YYYY-MM-DD");
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.weekSessions(start, end, "");
                    if (response.data.success == true) {
                        // var sess = [];
                        this.week = response.data.data;
                        // for (var i = 0; i < sess.length; i++) {
                        //   if (sess[i].scheme == "live") {
                        //     this.week.push(sess[i]);
                        //   }
                        // }
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getBooks() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getBooks();
                    if (response.data.success == true) {
                        this.books = response.data.data;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async addBook(bid) {
                var alert = {};
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.saveBook(bid);
                    if (response.data.success == true) {
                        // this.books = (response.data.data)
                        this.getBooks();
                        alert = {
                            text: [],
                            head: response.data.flash.notice,
                            type: "alert-success d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    } else {
                        //console.log("error");
                        alert = {
                            text: response.data.errors[0],
                            head: response.data.flash.error,
                            type: "alert-danger d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async removeBook(bid) {
                var alert = {};
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.removeBook(bid);
                    if (response.data.success == true) {
                        // this.books = (response.data.data)
                        this.getBooks();
                        alert = {
                            text: [],
                            head: response.data.flash.notice,
                            type: "alert-success d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    } else {
                        //console.log("error");
                        alert = {
                            text: response.data.errors[0],
                            head: response.data.flash.error,
                            type: "alert-danger d-block",
                        };
                        this.$bus.$emit("flash", alert);
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
        },
    };
</script>