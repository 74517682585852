var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"main-det"},[(_vm.sessions.length > 0)?_c('div',{staticClass:"today"},[_c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"row m-0"},[_c('h5',[_vm._v("Recorded Sessions")]),_c('button',{staticClass:"btn-link prev btn",attrs:{"disabled":_vm.sessions.length > 0 && _vm.sR == 0},on:{"click":function($event){return _vm.prevRecent()}}},[_c('i',{staticClass:"fas fa-chevron-left"})]),_c('button',{staticClass:"btn-link btn",attrs:{"disabled":_vm.sessions.length > 0 && _vm.sessions.length <= _vm.sR + 1},on:{"click":function($event){return _vm.nextRecent()}}},[_c('i',{staticClass:"fas fa-chevron-right"})])])]),_c('div',{staticClass:"row erow m-0 w-100"},_vm._l((_vm.sessions.slice(_vm.sR)),function(ins,index){return _c('div',{key:'ht' + index,staticClass:"htoday col-lg-4 col-md-6"},[(
              ins.content_host_type == 'VimeoVideo' ||
                ins.content_host_type == 'WellFitVideo'
            )?_c('router-link',{attrs:{"to":{ name: 'ShowEpisode', params: { id: ins.id } }}},[_c('div',{},[_c('img',{staticClass:"w-100",attrs:{"src":ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''}}),_c('p',{staticClass:"d-inline-block mb-0"},[_vm._v(_vm._s(ins.title))]),_c('p',{staticClass:"d-inline-block mb-1 sesstime"},[_vm._v(" "+_vm._s(_vm.moment(ins.start_at) .tz(_vm.moment.tz.guess(true)) .format("dddd MMMM DD, YYYY h:mmA z"))+" ")])])]):_c('a',{attrs:{"target":"_blank","href":ins.content_url}},[_c('div',{},[_c('img',{staticClass:"w-100",attrs:{"src":ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''}}),_c('p',{staticClass:"d-inline-block mb-0"},[_vm._v(_vm._s(ins.title))]),_c('p',{staticClass:"d-inline-block mb-1 sesstime"},[_vm._v(" "+_vm._s(_vm.moment(ins.start_at) .tz(_vm.moment.tz.guess(true)) .format("dddd MMMM DD, YYYY h:mmA z"))+" ")])])])],1)}),0)]):_vm._e(),(_vm.books.length > 0)?_c('div',{staticClass:"ebooks"},[_vm._m(0),_c('div',{staticClass:"row m-0"},_vm._l((_vm.books),function(book,index){return _c('a',{key:'book' + index,staticClass:"col-lg-4 col-md-6 pl-0 mt-3",attrs:{"target":"_blank","href":book.content_url}},[_c('div',{staticClass:"eblk res mr-0"},[_c('div',{staticClass:"row arow m-0"},[_c('img',{attrs:{"src":book.thumbnail_urls ? book.thumbnail_urls.large : ''}}),_c('h4',{staticClass:"mb-0 ml-3"},[_vm._v(" "+_vm._s(book.title)+" "),_c('p',[_vm._v(_vm._s(book.author))])]),(book.bookmarked == false)?_c('font-awesome-icon',{staticClass:"float-right",attrs:{"icon":['far', 'bookmark']},on:{"click":function($event){$event.preventDefault();_vm.addBook(book.id);
                  _vm.ga(
                    'send',
                    'Save/Download Workout',
                    [_vm.Button],
                    [_vm.Click],
                    [_vm.ins.title],
                    [_vm.Saved]
                  );}}}):_c('font-awesome-icon',{staticClass:"float-right",attrs:{"icon":['fas', 'bookmark']},on:{"click":function($event){$event.preventDefault();return _vm.removeBook(book.id)}}})],1),_c('p',[_vm._v(_vm._s(book.description))])])])}),0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-12 p-0"},[_c('div',{staticClass:"row m-0"},[_c('h5',[_vm._v("Downloads & Links")])])])
}]

export { render, staticRenderFns }