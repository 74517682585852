<template>
  <div id="main-layout" class="wf-back">
     <div  v-if="salert"
              class="alert wal alert-dismissible fade show"
              :class="alert.type"
              role="alert"
              :style="alert.type ? 'display:block;position:fixed ' : 'display:none'"
            >
            <div id="alert" >
              <p class="alert-heading m-0">
                <strong>{{ alert.head }}</strong>
                <span class="alert-text ml-2">{{ alert.text }}</span>
              </p>
            </div>
            </div>
    <div class="sess-det">
      <div v-if="flash" class="alert" :class="flash_type" role="alert">
        {{ flash_msg }}
      </div>
      <nav class="navbar navbar-expand-lg main-nav">
        <router-link
          class="navbar-brand ml-0"
          to="/customer/dashboard"
          @click.native="page = ''"
          ><img class="dash-logo" src="../../assets/wellfit-logo@2x.png"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"
            ><i class="fas fa-bars fa-lg"></i
          ></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav nav">
            <div class="dropdown dropdown-menu-right">
              <a href="#" style="display:flex; align-items: center;" class="nav-link pl-0 bg-transparent text-white dropdown-toggle" role="button" id="pillarsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <svg class="mr-2 mb-1" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                  <path opacity="1" style="fill: currentColor;" d="M243.4 2.6l-224 96c-14 6-21.8 21-18.7 35.8S16.8 160 32 160v8c0 13.3 10.7 24 24 24H456c13.3 0 24-10.7 24-24v-8c15.2 0 28.3-10.7 31.3-25.6s-4.8-29.9-18.7-35.8l-224-96c-8-3.4-17.2-3.4-25.2 0zM128 224H64V420.3c-.6 .3-1.2 .7-1.8 1.1l-48 32c-11.7 7.8-17 22.4-12.9 35.9S17.9 512 32 512H480c14.1 0 26.5-9.2 30.6-22.7s-1.1-28.1-12.9-35.9l-48-32c-.6-.4-1.2-.7-1.8-1.1V224H384V416H344V224H280V416H232V224H168V416H128V224zM256 64a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
                </svg>
                PILLARS
                <span>
                  <i class="fas fa-angle-down ml-3"></i>
                </span>
              </a>
              <div class="dropdown-menu" aria-labelledby="pillarsMenuButton" style="right:-70px; margin-top: 8px;">
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'move' } }"
                    :class="
                this.$route.params.cat === 'move'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                    <path opacity="1" style="fill: currentColor;" d="M96 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32V224v64V448c0 17.7-14.3 32-32 32H128c-17.7 0-32-14.3-32-32V384H64c-17.7 0-32-14.3-32-32V288c-17.7 0-32-14.3-32-32s14.3-32 32-32V160c0-17.7 14.3-32 32-32H96V64zm448 0v64h32c17.7 0 32 14.3 32 32v64c17.7 0 32 14.3 32 32s-14.3 32-32 32v64c0 17.7-14.3 32-32 32H544v64c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32V288 224 64c0-17.7 14.3-32 32-32h32c17.7 0 32 14.3 32 32zM416 224v64H224V224H416z"/>
                  </svg>
                  Move Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'eat' } }"
                    :class="
                this.$route.params.cat === 'eat'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                      <path opacity="1" style="fill: currentColor;" d="M214.6 169.4L192 146.7l0 0L45.3 0S0 40 0 104c0 70.7 63.5 136.2 154.7 226.8L265.4 220.1l-50.7-50.7zM441.4 486.6L464 509.3 509.3 464l-22.6-22.6L355.9 310.6l-45.3 45.3L441.4 486.6zM488 24L472 8 348.6 78.5C321 94.3 304 123.6 304 155.3c0 19.4 6.4 38.1 18 53.4L89.4 441.4 66.7 464 112 509.3l22.6-22.6L367.2 254c15.3 11.6 34 18 53.4 18c31.8 0 61.1-17 76.8-44.6L568 104 552 88 448 192l-16-16L528 64 512 48 400 144l-16-16L488 24z"/>
                    </svg>
                  </span>
                  Eat Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'drink' } }"
                    :class="
                this.$route.params.cat === 'drink'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="18" viewBox="0 0 576 512">
                      <path opacity="1" style="fill: currentColor;"  d="M144 64v50.7c0 8.5-3.4 16.6-9.4 22.6l-29.3 29.3c-6 6-9.4 14.1-9.4 22.6V256c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V189.3c0-8.5-3.4-16.6-9.4-22.6l-29.3-29.3c-6-6-9.4-14.1-9.4-22.6V64H384h64c70.7 0 128 57.3 128 128s-57.3 128-128 128H416v32c0 53-43 96-96 96H128c-53 0-96-43-96-96V96c0-17.7 14.3-32 32-32h80zM416 256h32c35.3 0 64-28.7 64-64s-28.7-64-64-64H416V256z"/></svg>
                  </span>
                  Drink Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'feel' } }"
                    :class="
                this.$route.params.cat === 'feel'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" height="16" width="16" viewBox="0 0 512 512">
                      <path opacity="1" style="fill: currentColor;" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zm53.5-96.7l0 0 0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0zm160 0l0 0-.2-.2c-.2-.2-.4-.5-.7-.9c-.6-.8-1.6-2-2.8-3.4c-2.5-2.8-6-6.6-10.2-10.3c-8.8-7.8-18.8-14-27.7-14s-18.9 6.2-27.7 14c-4.2 3.7-7.7 7.5-10.2 10.3c-1.2 1.4-2.2 2.6-2.8 3.4c-.3 .4-.6 .7-.7 .9l-.2 .2 0 0 0 0 0 0c-2.1 2.8-5.7 3.9-8.9 2.8s-5.5-4.1-5.5-7.6c0-17.9 6.7-35.6 16.6-48.8c9.8-13 23.9-23.2 39.4-23.2s29.6 10.2 39.4 23.2c9.9 13.2 16.6 30.9 16.6 48.8c0 3.4-2.2 6.5-5.5 7.6s-6.9 0-8.9-2.8l0 0 0 0 0 0z"/></svg>
                  </span>
                  Feel Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'live' } }"
                    :class="
                this.$route.params.cat === 'live'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 576 512">
                      <path style="fill: currentColor;" d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z"/></svg>
                  </span>
                  Live Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'sleep' } }"
                    :class="
                this.$route.params.cat === 'sleep'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
                      <path style="fill: currentColor;" d="M48 256C48 141.1 141.1 48 256 48c30.7 0 59.9 6.7 86.1 18.6c12.1 5.5 26.3 .2 31.8-11.9s.2-26.3-11.9-31.8C329.6 8.2 293.7 0 256 0C114.6 0 0 114.6 0 256S114.6 512 256 512s256-114.6 256-256c0-37.7-8.2-73.6-22.9-105.9c-5.5-12.1-19.7-17.4-31.8-11.9s-17.4 19.7-11.9 31.8C457.3 196.1 464 225.3 464 256c0 114.9-93.1 208-208 208S48 370.9 48 256zM256 424a40 40 0 1 0 0-80 40 40 0 1 0 0 80zm64-296h25.4l-36.7 36.7c-4.6 4.6-5.9 11.5-3.5 17.4s8.3 9.9 14.8 9.9h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H358.6l36.7-36.7c4.6-4.6 5.9-11.5 3.5-17.4s-8.3-9.9-14.8-9.9H320c-8.8 0-16 7.2-16 16s7.2 16 16 16zM416 16c0 8.8 7.2 16 16 16h25.4L420.7 68.7c-4.6 4.6-5.9 11.5-3.5 17.4s8.3 9.9 14.8 9.9h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H470.6l36.7-36.7c4.6-4.6 5.9-11.5 3.5-17.4S502.5 0 496 0H432c-8.8 0-16 7.2-16 16zM351.6 252c-10.6 0-19.9-3.8-25.4-9.7c-7.6-8.1-20.2-8.5-28.3-.9s-8.5 20.2-.9 28.3c14.5 15.5 35.2 22.3 54.6 22.3s40.1-6.8 54.6-22.3c7.6-8.1 7.1-20.7-.9-28.3s-20.7-7.1-28.3 .9c-5.5 5.8-14.8 9.7-25.4 9.7zm-217.4-9.7c-7.6-8.1-20.2-8.5-28.3-.9s-8.5 20.2-.9 28.3c14.5 15.5 35.2 22.3 54.6 22.3s40.1-6.8 54.6-22.3c7.6-8.1 7.1-20.7-.9-28.3s-20.7-7.1-28.3 .9c-5.5 5.8-14.8 9.7-25.4 9.7s-19.9-3.8-25.4-9.7z"/></svg>
                  </span>
                  Sleep Well</router-link
                >
                <router-link
                    :to="{ name: 'Dashboard', params: { cat: 'rest' } }"
                    :class="
                this.$route.params.cat === 'rest'
                  ? 'nav-link active'
                  : 'nav-link'
              "
                    style="display:flex; align-items: center;"
                >
                  <span>
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
                      <path style="fill: currentColor;" d="M32 32c17.7 0 32 14.3 32 32V320H288V160c0-17.7 14.3-32 32-32H544c53 0 96 43 96 96V448c0 17.7-14.3 32-32 32s-32-14.3-32-32V416H352 320 64v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"/></svg>
                  </span>
                  Rest Well</router-link
                >
              </div>
            </div>
            <router-link
              :to="{
                path: '/customer/masterclass',
                params: { cat: 'masterclass' },
              }"
              :class="
                this.$route.params.cat === 'masterclass'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('masterclass', 2)"
              >Masterclasses</router-link
            >
            <router-link
              :to="{
                path: '/customer/schedule',
                params: { cat: 'schedule' },
              }"
              :class="
                this.$route.params.cat == 'schedule'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('schedule', 3)"
              >Schedule</router-link
            >
            <router-link
              :to="{ name: 'Resources', params: { cat: 'resources' } }"
              :class="
                this.$route.params.cat == 'resources'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('resources', 4)"
              >Resources</router-link
            >
            <router-link
              v-if="promo == true"
              :to="{ name: 'Dashboard', params: { cat: 'promotional' } }"
              :class="
                this.$route.params.cat == 'promotional'
                  ? 'nav-link pl-0 active'
                  : 'nav-link pl-0 '
              "
              @click.native="loadPage('promotional', 5)"
              >{{ tag.label }}</router-link
            >
            <a class="nav-link pl-0" href="https://www.thewellfitclub.com/shop"
              >Store</a
            >
            <!-- <router-link :to="{ name: 'Dashboard', params: { cat: 'drink' }}" :class="this.$route.params.cat=='drink' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('drink',2)">Drink</router-link>
            <router-link :to="{ name: 'Dashboard', params: { cat: 'rest' }}" :class="this.$route.params.cat=='rest' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('rest',3)">Rest</router-link>
            <router-link :to="{ name: 'Dashboard', params: { cat: 'live' }}" :class="this.$route.params.cat=='live' ?'nav-link pl-0 active' :'nav-link pl-0 '" @click.native="loadPage('live',4)">Live</router-link> -->
          </div>
        </div>
        <div class="user float-right">
          <router-link to="/customer/saved" class=""
            ><i class="fas fa-bookmark mr-3"></i
          ></router-link>
          <img class="mr-2" v-if="user.avatar" :src="user.avatar" />
          <img class="mr-2" v-if="user.avatar_urls" :src="user.avatar_urls" />
          <p
            class="mb-0 dropdown-toggle"
            href="#"
            role="button"
            id="usernameL"
            data-toggle="dropdown"
            aria-haspopup="true"
            v-if="user.firstname && user.lastname"
          >
            {{ user.firstname }} {{ user.lastname }}
          </p>
          <p
            class="mb-0 dropdown-toggle"
            v-else
            href="#"
            role="button"
            id="usernameL"
            data-toggle="dropdown"
            aria-haspopup="true"
          >
            {{ user.name }}
          </p>
          <div class="dropdown show">
            <a
              class="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-angle-down"></i>
            </a>

            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <router-link to="/customer/account" class="dropdown-item"
                >My Account</router-link
              >
              <router-link to="/customer/saved" class="dropdown-item"
                >Saved</router-link
              >
              <!-- <router-link to="/customer/schedule" class="dropdown-item" >Schedule</router-link> -->
              <router-link to="/customer/redeem" class="dropdown-item"
                >Redeem Coupon</router-link
              >
              <!-- <router-link to="/customer/plans" class="dropdown-item" >Plans</router-link> -->
              <a
                class="dropdown-item"
                href="https://www.thewellfitclub.com/about"
                >About Us</a
              >
              <a
                class="dropdown-item"
                href="https://www.thewellfitclub.com/faqs"
                >FAQs</a
              >
              <a class="dropdown-item" @click="logout()">Log Out</a>
            </div>
          </div>
        </div>
      </nav>
      <div class="mainR">
        <router-view :window="window" />
        <!-- <keep-alive>
        </keep-alive> -->
      </div>
    </div>
    <div id="sess-footer">
      <div class="foot-details">
           <span class="pby">powered by</span>
        <img class="powered-logo" src="../../assets/Canopy-Logo.png" />
        <p class="mb-0 d-inline-block ml-3 res">
          2020 WellFit Ltd. All Rights Reserved.
          <br />
          <a href="/">Privacy Policy </a>/ <a href="/">Terms of Use</a>
        <a class="ogs" href="https://www.onegreatstudio.com/" target="_blank">
          <img class="" src="../../assets/1gs-logo@2x.png" />
        </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import custom from "../../javascript/custom";
import router from "@/router";
import UserService from "../../services/userService";
import axios from "axios";
import $ from "jquery";

export default {
  name: "MainPage",
  data() {
    return {
      page: "",
      flash: false,
      flash_type: "",
      flash_msg: "",
      tag: {},
      promo: false,
      user: {
        firstname: "",
        lastname: "",
        name: "",
        avatar: "",
        avatar_urls: "",
      },
      id: "",
      window: {
        width: 0,
        height: 0,
      },
      alert: {
        text: "",
        head: "",
        type: "",
      },

    salert:false

    };
  },
  created() {
    this.id = this.$session.get("user").id;
  },
  computed: {},
  mounted() {
    this.getProfile();
    this.$bus.$on("cusup", () => {
      this.getProfile();
    });
    this.$bus.$on("flash", (data) => {
      this.alert.text = data.text;
      if (this.alert.text[0] != "") {
        this.alert.text = this.alert.text[0];
      }
      this.alert.head = data.head;
      this.alert.type = data.type;
      this.salert=true;
      setTimeout(function () {
                   if( document.getElementById("alert") != null){
             document.getElementById("alert").classList.remove("d-block");
           }
        this.removeAl()
      }.bind(this), 5000);
    });


  },

  Updated(){
    this.actSlide()
  },
  components: {},

  methods: {
    removeAl(){
      this.salert=false;
      delete this.alert.type
    },

    actSlide(){
      const slider = document.querySelector('.erow');
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
  isDown = true;
  slider.classList.add('active');
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
});
slider.addEventListener('mouseleave', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mouseup', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = (x - startX) * 3; //scroll-fast
  slider.scrollLeft = scrollLeft - walk;
  console.log(walk);
});
    },
  
    loadPage(pg, na) {
      this.page = pg;
      custom.updateNav(na);
      $(".navbar-toggler").click();
    },
    logout() {
      this.$session.clear();
      sessionStorage.clear();
      localStorage.clear();
      this.$session.destroy();
      router.push({ path: "/" });
    },
    async getProfile() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getProfile(this.id);
        if (response.data.success == true) {
          this.user = response.data.data.profile;
          this.user.avatar = response.data.data.profile.avatar.large;
          this.getTags();
        } else {
          //console.log("error");
        }
      } catch (err) {
        if((err.response.status)==401){

          this.logout() 
        }
      }
    },
    async getTags() {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.$session.get("token");
      try {
        const response = await UserService.getTags();
        if (response.data.success == true) {
          for (var x = 0; x < response.data.data.length; x++) {
            if (response.data.data[x].promo == true) {
              this.tag = response.data.data[x];
              this.$session.set("promo", this.tag.label);
              this.promo = true;
            }
          }
        } else {
          //console.log("error");
        }
      } catch (err) {
        //console.log(err);
      }
    },
  },
};
</script>