<template>
  <div class="offset-md-3">
    <div class="billing-list table-list">
      <paginate
        name="languages"
        :list="langs"
        :per="lim"
        class="pl-0"
      >
        
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Plan</th>
              <th scope="col">Date</th>
              <th scope="col">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-det spacer" v-for="(lang,index) in paginated('languages')" :key="bill+index">
              <td><b>{{lang.plan_name}}</b></td>
              <td>{{moment(lang.paid_at).format('LL')}}</td>
              <td v-if="lang.plan_id != 53">${{lang.amount | numFormat }} </td>
              <td v-else>$30</td>
            </tr>
          </tbody>
        </table>
      </paginate>
      <paginate-links class="pl-0" for="languages" :async="true" :limit="lim" :show-step-links="true"></paginate-links>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from '../../../services/userService'
import axios from 'axios'

export default {
  name: 'BillingPage',
  data () {
    return {    
      langs: [],
      lim:6,
      paginate: ['languages']
    }
  },
  created(){
  
  },
  computed:{

  },
  mounted(){
    this.getPayments()
  }, 
  components: {
  },      
  methods: {
     async getPayments(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.getSubPay();
        if(response.data.success == true){
         
         this.langs=response.data.data.records
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
      
    },
  }  
}
</script>