<template>
  <div id="main-layout" class="wf-back" style="overflow-x: hidden">
    <div class="sess-det">
      <div
        v-bind:style="{
          'background-image':
            'linear-gradient(to bottom,transparent , #1D1D1D),url(' +
            presenter.header_background_urls.large +
            ')',
          height: '350px',
          padding: '20px 0px',
        }"
      >
        <nav
          class="navbar navbar-expand-lg main-nav"
          style="background: transparent !important; position: absolute"
        >
          <router-link
            class="navbar-brand ml-0"
            to="/customer/dashboard"
            @click.native="page = ''"
            ><img class="dash-logo" src="../../../assets/wellfit-logo@2x.png"
          /></router-link>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"
              ><i class="fas fa-bars fa-lg"></i
            ></span>
          </button>
          <div
            class="collapse navbar-collapse"
            id="navbarNavAltMarkup"
            style="background: transparent !important"
          >
            <div class="navbar-nav nav">
              <!-- <router-link :to="{ name: 'Dashboard', params: { cat: 'move' }}"
                :class="this.$route.params.cat=='move' ?'nav-link pl-0 active' :'nav-link pl-0 '"
                @click.native="loadPage('move',0)">Move</router-link>
              <router-link :to="{ name: 'Dashboard', params: { cat: 'eat' }}"
                :class="this.$route.params.cat=='eat' ?'nav-link pl-0 active' :'nav-link pl-0 '"
                @click.native="loadPage('eat',1)">Eat</router-link>
              <router-link :to="{ name: 'Dashboard', params: { cat: 'drink' }}"
                :class="this.$route.params.cat=='drink' ?'nav-link pl-0 active' :'nav-link pl-0 '"
                @click.native="loadPage('drink',2)">Drink</router-link>
              <router-link :to="{ name: 'Dashboard', params: { cat: 'rest' }}"
                :class="this.$route.params.cat=='rest' ?'nav-link pl-0 active' :'nav-link pl-0 '"
                @click.native="loadPage('rest',3)">Rest</router-link>
              <router-link :to="{ name: 'Dashboard', params: { cat: 'live' }}"
                :class="this.$route.params.cat=='live' ?'nav-link pl-0 active' :'nav-link pl-0 '"
                @click.native="loadPage('live',4)">Live</router-link> -->
              <router-link
                :to="{ name: 'Dashboard', params: { cat: 'move' } }"
                :class="
                  this.$route.params.cat == 'move'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('move', 0)"
                >Move Well</router-link
              >
              <router-link
                :to="{ name: 'Dashboard', params: { cat: 'eat' } }"
                :class="
                  this.$route.params.cat == 'eat'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('eat', 1)"
                >Eat Well</router-link
              >
              <router-link
                :to="{
                  path: '/customer/masterclass',
                  params: { cat: 'masterclass' },
                }"
                :class="
                  this.$route.params.cat == 'masterclass'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('masterclass', 2)"
                >Masterclasses</router-link
              >
              <router-link
                :to="{
                  path: '/customer/schedule',
                  params: { cat: 'schedule' },
                }"
                :class="
                  this.$route.params.cat == 'schedule'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('schedule', 3)"
                >Schedule</router-link
              >
              <router-link
                :to="{ name: 'Resources', params: { cat: 'resources' } }"
                :class="
                  this.$route.params.cat == 'resources'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('resources', 4)"
                >Resources</router-link
              >
              <router-link
                v-if="promo == true"
                :to="{ name: 'Dashboard', params: { cat: 'promotional' } }"
                :class="
                  this.$route.params.cat == 'promotional'
                    ? 'nav-link pl-0 active'
                    : 'nav-link pl-0 '
                "
                @click.native="loadPage('promotional', 5)"
                >{{ tag }}</router-link
              >
              <a
                class="nav-link pl-0"
                href="https://www.thewellfitclub.com/shop"
                >Store</a
              >
            </div>
            <div class="user float-right">
              <router-link to="/customer/saved" class=""
                ><i
                  style="color: white !important"
                  class="fas fa-bookmark mr-3"
                ></i
              ></router-link>
              <img class="mr-2" v-if="user.avatar" :src="user.avatar" />
              <img
                class="mr-2"
                v-if="user.avatar_urls"
                :src="user.avatar_urls"
              />
              <p class="mb-0" v-if="user.firstname && user.lastname">
                {{ user.firstname }} {{ user.lastname }}
              </p>
              <p class="mb-0" v-else>{{ user.name }}</p>
              <div class="dropdown show">
                <a
                  class="dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i
                    class="fas fa-angle-down"
                    style="color: white !important"
                  ></i>
                </a>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <router-link to="/customer/account" class="dropdown-item"
                    >My Account</router-link
                  >
                  <router-link to="/customer/saved" class="dropdown-item"
                    >Saved</router-link
                  >
                  <router-link to="/customer/redeem" class="dropdown-item"
                    >Redeem Coupon</router-link
                  >
                  <router-link to="/customer/plans" class="dropdown-item"
                    >Plans</router-link
                  >
                  <a class="dropdown-item" @click="logout()">Log Out</a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div id="saved" class="pb-0">
          <div class="row mt-4">
            <div class="col-lg-8">
              <h1>{{ presenter.name }}</h1>
              <p v-if="presenter.instructors.length == 1">
                - with Trainer {{ presenter.instructors[0].name }}
              </p>
              <p v-else>
                - with Trainers
                <span
                  v-for="(ins, index) in presenter.instructors"
                  :key="index + 'instruc'"
                  >{{ ins.name }}
                </span>
              </p>
            </div>
          </div>
          <div class="mt-3">
            <input
              type="text"
              v-model="keywords"
              placeholder="Search Videos"
              class="search"
            />
          </div>
        </div>
      </div>
      <div class="mainR">
        <div id="saved" class="pt-0">
          <!-- <div class="mt-4" v-if="items.length==0">
        <h3 class="text-center p-5">There are no sessions available</h3>
      </div> -->
          <div v-if="items.length > 0">
            <div class="mt-4">
              <!-- <label>Episodes</label> -->
              <!-- <paginate name="items" :list="items" :per="lim" class="w-100 p-0"> -->
              <div class="row m-0 pagbloc">
                <div
                  v-for="(ins, index) in items"
                  :key="'ht' + index"
                  class="htoday col-lg-4 col-md-6 mb-4 pl-0"
                >
                  <router-link
                    v-if="
                      ins.content_host_type == 'VimeoVideo' ||
                        ins.content_host_type == 'WellFitVideo'
                    "
                    :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                  >
                    <div class="">
                      <img
                        class="w-100"
                        :src="
                          ins.thumbnail_urls != null
                            ? ins.thumbnail_urls.large
                            : ''
                        "
                      />
                      <p class="d-inline-block w-95">
                        {{ ins.title }}
                        {{
                          moment(ins.start_at)
                            .tz(moment.tz.guess(true))
                            .format("dddd MMMM DD, YYYY h:mmA z")
                        }}
                      </p>
                      <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../assets/live.png"/></span> -->
                      <font-awesome-icon
                        class="float-right probook"
                        v-if="ins.bookmarked == false"
                        v-on:click.prevent="
                          addVideo(ins.id);
                          ga(
                            'send',
                            'Save/Download Workout',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );
                        "
                        admin
                        :icon="['far', 'bookmark']"
                      />
                      <font-awesome-icon
                        class="float-right probook"
                        v-else
                        v-on:click.prevent="removeVideo(ins.id)"
                        :icon="['fas', 'bookmark']"
                      />

                      <!-- <h5>Add to saved</h5> -->
                      <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../../assets/live.png"/></span> -->
                    </div>
                  </router-link>
                  <a
                    v-else
                    target="_blank"
                    :href="ins.content_url"
                    onclick="  ga(
                            'send',
                            'Click_Pre-Recorded_workouts',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );"
                  >
                    <div class="">
                      <img
                        class="w-100"
                        :src="
                          ins.thumbnail_urls != null
                            ? ins.thumbnail_urls.large
                            : ''
                        "
                      />
                      <p class="d-inline-block w-95">
                        {{ ins.title }}
                        {{
                          moment(ins.start_at)
                            .tz(moment.tz.guess(true))
                            .format("dddd MMMM DD, YYYY h:mmA z")
                        }}
                      </p>

                      <font-awesome-icon
                        class="float-right probook"
                        v-if="ins.bookmarked == false"
                        v-on:click.prevent="
                          addVideo(ins.id);
                          ga(
                            'send',
                            'Save/Download Workout',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );
                        "
                        :icon="['far', 'bookmark']"
                      />
                      <font-awesome-icon
                        class="float-right probook"
                        v-else
                        v-on:click.prevent="removeVideo(ins.id)"
                        :icon="['fas', 'bookmark']"
                      />
                      <!-- <span class="live" v-if="ins.scheme=='live'"> <img class="live-md" src="../../../../assets/live.png"/></span> -->
                    </div>
                  </a>
                </div>
              </div>
              <ul class="pl-0 progpag items paginate-links">
                <li
                  v-if="pages > 0"
                  @click="getVideos(1)"
                  class="left-arrow disabled"
                >
                  <a>«</a>
                </li>

                <li
                  :class="p == current ? 'number active pg' : 'number pg'"
                  v-for="(p, index) in pages"
                  :key="pag + index"
                >
                  <span
                    v-if="Math.abs(p - current) < 4 || p == pages || p == 1"
                  >
                    <a
                      @click="getVideos(p)"
                      :class="{
                        current: current === p,
                        last: p == pages && Math.abs(p - current) > 4,
                        first: p == 1 && Math.abs(p - current) > 4,
                      }"
                      >{{ p }}</a
                    >
                  </span>
                </li>
                <li
                  v-if="pages > 0"
                  @click="getVideos(pages)"
                  class="right-arrow disabled"
                >
                  <a>»</a>
                </li>
              </ul>
              <!-- </paginate>
              <paginate-links
                class="progpag pl-0"
                for="items"
                :async="true"
                :limit="lim"
                :show-step-links="true"
              >
              </paginate-links> -->
            </div>
          </div>
          <div v-if="prog.length > 0" class="workouts">
            <div class="col-sm-12 p-0">
              <div class="row m-0">
                <h5>Other Sessions</h5>
                <button
                  :disabled="prog.length > 0 && sB == 0"
                  class="btn-link btn prev"
                  @click="prevBook()"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button
                  :disabled="prog.length > 0 && prog.length <= sB + 1"
                  class="btn-link btn"
                  @click="nextBook()"
                >
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
            <div class="row m-0 erow w-100">
              <router-link
                @click.native="loadProg(ins.id)"
                class="col-lg-2 pl-0"
                :to="{ name: 'Episodes', params: { id: ins.id } }"
                v-for="(ins, index) in prog.slice(sB)"
                :key="'category' + index"
              >
                <!-- <a  class="col-lg-2 pl-0"   :href="'/customer/programs/'+ins.id + '/session'"  v-for="(ins,index) in prog.slice(sB, eB)" :key="'category'+index"> -->
                <div class="workout">
                  <img
                    :src="
                      ins.thumbnail_urls != null ? ins.thumbnail_urls.large : ''
                    "
                  />
                  <!-- <p >{{ins.name}}</p> -->
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="sess-footer">
      <div class="foot-details">
        <img class="powered-logo" src="../../../assets/Canopy-Logo.png" />
        <p class="mb-0 d-inline-block ml-3 res">
          2020 WellFit Ltd. All Rights Reserved.
          <br />
          <a href="/">Privacy Policy </a>/ <a href="/">Terms of Use</a>
          <a class="ogs" href="https://www.onegreatstudio.com/" target="_blank">
            <img class="" src="../../../assets/1gs-logo@2x.png" />
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
    // import custom from '../../javascript/custom'
    import UserService from "../../../services/userService";
    import axios from "axios";
    import router from "@/router";

    export default {
        name: "ProgramPage",
        data() {
            return {
                page: "",
                sB: 0,
                items: [],
                paginate: ["items"],
                // lim: 12,
                presenter: {},
                id: "",
                keywords: "",
                plan: {},
                mid: "",
                prog: [],
                tag: {},
                promo: false,
                user: {
                    firstname: "",
                    lastname: "",
                    name: "",
                    avatar: "",
                    avatar_urls: "",
                },
                current: 1,
                pages: 0,
            };
        },
        created() {
            this.id = this.$route.params.id;
            this.mid = this.$session.get("user").id;
        },
        mounted() {
            this.tag = this.$session.get("promo");
            if (this.tag != "") {
                this.promo = true;
            }
            this.getProfile();
        },
        updated() {},
        computed: {},
        components: {},
        watch: {
            keywords: "searchVideos",
            items: function() {
                window.scrollTo(0, 0);
            },
        },
        methods: {
            nextBook() {
                this.sB += 1;
            },
            prevBook() {
                this.sB -= 1;
            },
            async getProfile() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getProfile(this.id);
                    if (response.data.success == true) {
                        this.plan = response.data.data.profile.current_subscription;
                        this.user = response.data.data.profile;
                        this.user.avatar = response.data.data.profile.avatar.large;
                        if (this.plan != null) {
                            this.getPresenter();
                            this.getVideos(this.current);
                            this.getProg();
                            this.$session.set("subscription", true);
                        } else {
                            this.$session.set("subscription", false);
                            router.push({
                                path: "/customer/plans",
                            });
                        }
                        // this.user.profile.avatar = response.data.data.profile.avatar.large
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            loadProg(item) {
                this.id = item;
                this.getPresenter();
                this.getVideos(this.current);
                this.getProg();
            },
            async getProg() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getPrograms();
                    if (response.data.success == true) {
                        this.prog = response.data.data;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getPresenter() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getProgram(this.id);
                    if (response.data.success == true) {
                        this.presenter = response.data.data.program;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getVideos(page) {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getSession(this.id, page);
                    if (response.data.success == true) {
                        this.items = response.data.data;
                        this.pages = response.data.pagination.total_pages;
                        this.current = response.data.pagination.page;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async searchVideos(words) {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.searchSession(
                        this.id,
                        words,
                        this.current
                    );
                    if (response.data.success == true) {
                        this.items = response.data.data;
                        this.pages = response.data.pagination.total_pages;
                        this.current = response.data.pagination.page;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async addVideo(bid) {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.saveSession(bid);
                    if (response.data.success == true) {
                        // this.books = (response.data.data)
                        this.getVideos(this.current);
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async removeVideo(bid) {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.removeSession(bid);
                    if (response.data.success == true) {
                        // this.books = (response.data.data)
                        this.getVideos(this.current);
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            logout() {
                this.$session.clear();
                sessionStorage.clear();
                localStorage.clear();
                this.$session.destroy();
                router.push({
                    path: "/",
                });
            },
        },
    };
</script>