<template>
  <div class="main-det">
    <div class="plans col-lg-10 offset-md-1 mt-5">
      <h3 class="text-center"> Select a plan</h3>
      <div class="all-plans row mt-3 justify-content-center">
        <!-- <router-link class="rlink" to="/customer/account/subscription" @click.native="selectPlan(pl)" v-for="(pl,index) in plans" :key="pl+index"> -->
        <div class="text-center pl" @click="selectPlan(pl)" v-for="(pl,index) in plans" :key="pl+index">
            <h4>{{pl.name}}</h4>
            <button class="trbtn" v-if="pl.trial_period_amount >0">{{pl.trial_period_amount}}-<span class="capi">{{pl.trial_period_unit}}</span> Free Trial</button>
            <p class="mt-4 pldes">{{pl.description}}</p>
            <br>
            <button v-if="pl.trial_period_amount >0">Get Free Trial</button>
            <button v-else-if="pl.price!='free'">${{pl.price}} {{pl.currency}}<span>/ <span v-if="pl.subscription_period_amount>1">{{pl.subscription_period_amount}}</span> <span class="capi">{{pl.subscription_period_unit}}</span><span v-if="pl.subscription_period_amount>1">s</span></span></button>
            <button v-else>{{pl.price}}</button>

            <p  class="aftrpr" v-if="pl.trial_period_amount >0">Then ${{pl.price}} {{pl.currency}}<span>/ <span v-if="pl.subscription_period_amount>1">{{pl.subscription_period_amount}}</span><span class="capi"> {{pl.subscription_period_unit}}</span><span v-if="pl.subscription_period_amount>1">s</span></span></p>
        </div>
        <!-- </router-link> -->
      </div><h5  class="text-center mt-4">Do you have a coupon or gift certificate?
      </h5>
      <p  class="text-center">

         <router-link to="/customer/redeem"
                ><button class="mbtn">Redeem It Now</button></router-link>
      </p>
      <br>
      <br>
    
      <!-- <p class="back ml-4" @click="hasHistory() 
    ? $router.go(-1) 
    : $router.push('/dashboard')" ><i class="fas fa-chevron-left"></i> Go Back</p> -->
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from '../../../services/userService'
import axios from 'axios'

export default {
  name: 'PlansPage',
  data () {
    return {    
      plans:[]
    }
  },
  created(){
    this.getPlans()
  },
  computed:{

  },
  mounted(){
  }, 
  components: {
  },      
  methods: {
    //  hasHistory () { return window.history.length > 2 },
     async getPlans(){
      axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.getPlans();
        if(response.data.success == true){
          this.plans = (response.data.data.records)    
          this.$session.set('ptok',response.data.data.token)     
        }
        else{
          //console.log("error")
        }
      } catch (err){
        //console.log(err)
      }
    },
    selectPlan(pl){
      //  this.$bus.$emit('plan',pl );
          this.$session.set('plan',pl)
      this.plan=this.$session.get('plan')
      // window.location.replace("https://subscribe.thewellfitclub.com/plans/"+this.plan.id+"/subscribe?token="+this.$session.get("ptok"));
              window.location.replace("https://subscribe.thewellfitclub.com/plans/"+this.plan.id+"/subscribe?token="+this.$session.get("ptok"));

    
    }
  }  
}
</script>