var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"admin-layout"}},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 col-md-2 wf-back admin-nav"},[_c('img',{staticClass:"admin-logo",attrs:{"src":require("../../assets/wellfit-logo@2x.png")}}),_c('nav',{staticClass:"nav flex-column mt-4"},[_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Users' }},nativeOn:{"click":function($event){return _vm.loadPage('users', 0)}}},[_vm._v("Users")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Tags' }},nativeOn:{"click":function($event){return _vm.loadPage('tags', 0)}}},[_vm._v("Tags")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Seasons' }},nativeOn:{"click":function($event){return _vm.loadPage('seasons', 2)}}},[_vm._v("Seasons")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Sessions' }},nativeOn:{"click":function($event){return _vm.loadPage('sessions', 1)}}},[_vm._v("Sessions")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Programs' }},nativeOn:{"click":function($event){return _vm.loadPage('programs', 4)}}},[_vm._v("Programs")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'APlans' }},nativeOn:{"click":function($event){return _vm.loadPage('plans', 5)}}},[_vm._v("Plans")]),_c('router-link',{staticClass:"nav-link pl-0",attrs:{"to":{ name: 'Ebooks' }},nativeOn:{"click":function($event){return _vm.loadPage('ebooks', 6)}}},[_vm._v("Links")]),_c('router-link',{class:this.$route.name == 'Transactions'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0',attrs:{"to":{ name: 'Transactions' }},nativeOn:{"click":function($event){return _vm.loadPage('transactions', 7)}}},[_vm._v("Transactions")]),_c('router-link',{class:this.$route.name == 'Settings'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'Settings' }},nativeOn:{"click":function($event){return _vm.loadPage('settings', 8)}}},[_vm._v("Settings")]),_c('router-link',{class:this.$route.name == 'AAccount'
                ? 'nav-link pl-0 active'
                : 'nav-link pl-0 ',attrs:{"to":{ name: 'AAccount' }},nativeOn:{"click":function($event){return _vm.loadPage('account', 9)}}},[_vm._v("My Account")])],1)]),_c('div',{staticClass:"col-lg-10 admin-content pb-0 mb-0 col-md-10"},[_c('div',{staticClass:"adin"},[(_vm.salert)?_c('div',{staticClass:"alert wal alert-dismissible fade show",class:_vm.alert.type,style:(_vm.alert.type ? 'display:block;position:fixed ' : 'display:none'),attrs:{"role":"alert"}},[_c('div',{attrs:{"id":"alert"}},[_c('p',{staticClass:"alert-heading m-0"},[_c('strong',[_vm._v(_vm._s(_vm.alert.head))]),_c('span',{staticClass:"alert-text ml-2"},[_vm._v(_vm._s(_vm.alert.text))])])])]):_vm._e(),_c('div',{staticClass:"user d-block text-right"},[_c('a',{staticClass:"dropdown-toggle admenu",attrs:{"href":"#","role":"button","id":"dropdownMenuLink","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[(_vm.avatar)?_c('img',{staticClass:"mr-2",attrs:{"src":_vm.avatar}}):_vm._e(),(_vm.user.firstname && _vm.user.lastname)?_c('p',{staticClass:"mb-0 d-inline"},[_vm._v(" "+_vm._s(_vm.user.firstname)+" "+_vm._s(_vm.user.lastname)+" ")]):_c('p',{staticClass:"mb-0 d-inline"},[_vm._v(_vm._s(_vm.user.name))]),_c('i',{staticClass:"fas fa-angle-down addrop"})]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"dropdownMenuLink"}},[_c('a',{staticClass:"dropdown-item ad",on:{"click":function($event){return _vm.logout()}}},[_vm._v("Log Out")])])]),_c('router-view')],1),_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"sess-footer"}},[_c('div',{staticClass:"foot-details"},[_c('span',{staticClass:"pby"},[_vm._v("powered by")]),_c('img',{staticClass:"powered-logo",attrs:{"src":require("../../assets/can.png")}}),_c('p',{staticClass:"mb-0 d-inline-block ml-3 res"},[_vm._v(" 2020 WellFit Ltd. All Rights Reserved. "),_c('br'),_c('a',{attrs:{"href":"/"}},[_vm._v("Privacy Policy ")]),_vm._v("/ "),_c('a',{attrs:{"href":"/"}},[_vm._v("Terms of Use")]),_c('a',{staticClass:"ogs",attrs:{"href":"https://www.onegreatstudio.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/1gs-logo@2x.png")}})])])])])
}]

export { render, staticRenderFns }