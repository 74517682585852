<template>
  <div class="main-det col-lg-10 offset-lg-1">
    <div class="coupon-form mt-5">
      <h3 class="text-center"> Enter coupon code</h3>
      <input type="text" v-model="code"  class="input-field form-control col-lg-4 col-md-5 col-sm-12" placeholder="" required />
        <!-- <button  id="savecode" type="submit" class="form-btn ">Save User</button> -->
    </div>
    <div v-if="showplan==true" class="plans mt-5">
      <!-- <div>
        <h5 v-if="coupon.discount_type=='percentage'">Value: {{coupon.discount_value}}% OFF</h5>
        <h5 v-else>Value:${{coupon.discount_value}} OFF</h5>
        <h5>Expires: {{moment(coupon.expire_on).format('LL')}}</h5>
      </div> -->
      <h3 class="text-center"> Available plans</h3>
      <div  v-if="npl==false" class="all-plans row mt-5 justify-content-center">
        <!-- <a class="rlink"  @click.native="selectPlan(pl)" v-for="(pl,index) in plans" :key="pl+index"> -->
        <div class="text-center pl" v-for="(pl,index) in plans" :key="pl+index">
          <div  @click="selectDiscountedPlan(pl)" v-if="( pl.price==0) || (pl.discounted_price == 0)">
            <h4>{{pl.name}}</h4>
            <p class="mt-4 pldes">{{pl.description}}</p>
            <button v-if="pl.price!='free' && pl.price!=0">${{pl.discounted_price}} {{pl.currency}}<span>/ <span v-if="pl.subscription_period_amount>1">{{pl.subscription_period_amount}}</span> {{pl.subscription_period_unit}}<span v-if="pl.subscription_period_amount>1">s</span></span></button>
            <button v-else>${{pl.price}}</button>
          </div>
          <div  @click="selectPlan(pl)" v-else>

            <h4>{{pl.name}}</h4>
            <p class="mt-4 pldes">{{pl.description}}</p>
            <button v-if="pl.price!='free' && pl.price!=0">${{pl.discounted_price}} {{pl.currency}}<span>/ <span v-if="pl.subscription_period_amount>1">{{pl.subscription_period_amount}}</span> {{pl.subscription_period_unit}}<span v-if="pl.subscription_period_amount>1">s</span></span></button>
            <button v-else>${{pl.price}}</button>
          </div>
        </div>
        <!-- </a> -->
      </div>
    </div>
    <div v-if="npl==true">
      <h3 class="text-center"> No available plans</h3>
    </div>
  </div>
</template>

<script>
// import custom from '../../javascript/custom'
import UserService from '../../../services/userService'
import axios from 'axios'
import router from '@/router'

export default {
  name: 'RedeemPage',
  data () {
    return {    
      plans:[],
      code:"",
      showplan:false,
      coupon:{},
      npl:"",
      details:{

        subscription:{
          coupon_code:"",
          auto_renew:true
        }
      }
    }
  },
  created(){
    // this.getPlans()
  },
  computed:{

  },
  mounted(){
  }, 
  components: {
  }, 
   watch: {
      code: 'getPlans',
    },      
  methods: {
    //  hasHistory () { return window.history.length > 2 },
     async getPlans(){

       if(this.code != "" && this.code != null){
         axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
          try{
           const response = await UserService.getCouponPlans(this.code);
           if(response.data.success == true){
               if(response.data.data.records.length==0){
                 this.npl=true
            
             }
             else{
               this.npl=false
             }
             this.$session.set('ptok',response.data.data.token)
             this.coupon=response.data.data.records[0]
             if(this.coupon.plans){
               this.plans = (response.data.data.records[0].plans) 
             }
             else{
               this.plans=[]
             }
             if(this.plans.length>0){
   
               this.showplan=true    
             }
             else{
               this.showplan=false
             }
             // for(var i=0;i<this.plans.length();i++){
             //   this.plans[i].discount = this.plans[i].price - this.coupon.discount_value
             // }
           }
           else{
             //console.log("error")
           }
         } catch (err){
           // //console.log(err)
         }
       }
    },
    selectPlan(pl){
      //  this.$bus.$emit('planc',pl );
      // this.$session.set('coup',this.code)
      // this.$session.set('plan',pl)
      // this.plan=this.$session.get('plan')
      // window.location.replace("https://subscribe.thewellfitclub.com/plans/"+pl.id+"/subscribe?token="+this.$session.get("ptok")+"&coupon="+this.code);
        window.location.replace("https://subscribe.thewellfitclub.com/plans/"+pl.id+"/subscribe?token="+this.$session.get("ptok")+"&coupon="+this.code);

    },
    async selectDiscountedPlan(pl){
        var alert= {}
        this.details.subscription.coupon_code= this.code
           axios.defaults.headers.common['Authorization'] = 'Bearer ' +this.$session.get("token")
       try{
        const response = await UserService.newSubscription(pl.id,this.details);
        if(response.data.success == true){

           alert = {text:[],head:response.data.flash.notice,type:"alert-success d-block"}
            this.$bus.$emit('flash',alert); 
          //bt.innerText = response.data.flash.notice  
           setTimeout(function(){  
             router.push({ path: '/customer/dashboard' });     
      }, 500);
          
        }
        else{
          //console.log("error")
            alert = {
            text: [response.data.flash.error],
            head: "Error: ",
            type: "alert-danger d-block",
          };
          this.$bus.$emit("flash", alert);
        }
      } catch (err){
        // //console.log(err)
      }
    }
  }  
}
</script>