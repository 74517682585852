<template>
  <div class="main-det">
    <div class="row">
      <div class="col-lg-8">
        <h4 class="mb-3">Schedule</h4>
        <div>
          <div class="mb-5 dayblk">
            <!-- <h5 class="day">Sunday</h5> -->
            <div
              class=""
              v-for="(group, start_at) in days"
              :key="'wk' + start_at"
            >
              <!-- <h5 class="day" v-html="groupByDate(ins.start_at)"></h5> -->
              <h5 class="day">{{ moment(start_at).format("dddd") }}</h5>
              <div v-for="(ins, index) in group" :key="'day' + index">
                <router-link
                  v-if="
                    ins.content_host_type == 'VimeoVideo' ||
                      ins.content_host_type == 'WellFitVideo'
                  "
                  :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                >
                  <div class="row w-100 sess mb-3">
                    <div class="col-lg-2 presimg">
                      <img
                        :src="
                          ins.presenter != null
                            ? ins.presenter.avatar_urls.large
                            : ''
                        "
                        class="insimg"
                      />
                    </div>
                    <div class="col-lg-10 dayinfo daysess">
                      <div class="row">
                        <div class="col-lg-7 pl-0">
                          <p class="mb-0" v-if="ins.title != null">
                            {{ ins.title }}
                          </p>
                          <p class="mb-0 presn" v-if="ins.presenter != null">
                            {{ ins.presenter.name }}
                          </p>
                        </div>
                        <div class="col-lg-2 p-0">
                          <p class="mb-0">
                            {{ ins.start_at.format("h:mma z") }}
                            <br />
                            <span>Start Time</span>
                          </p>
                        </div>
                        <div class="col-lg-3 p-0 text-right">
                          <router-link
                            v-if="
                              ins.content_host_type == 'VimeoVideo' ||
                                ins.content_host_type == 'WellFitVideo'
                            "
                            :to="{
                              name: 'ShowEpisode',
                              params: { id: ins.id },
                            }"
                            onclick="  ga(
                            'send',
                            'Click Live Classes',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );"
                          >
                            <span class="jclass"
                              >Join Class <i class="far fa-play-circle ml-2"></i
                            ></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 text-right jmob">
                      <router-link
                        v-if="
                          ins.content_host_type == 'VimeoVideo' ||
                            ins.content_host_type == 'WellFitVideo'
                        "
                        :to="{ name: 'ShowEpisode', params: { id: ins.id } }"
                        onclick="   ga(
                            'send',
                            'Click Live Classes',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );"
                      >
                        <span class="jclass"
                          >Join Class <i class="far fa-play-circle ml-2"></i
                        ></span>
                      </router-link>
                    </div>
                  </div>
                </router-link>
                <a v-else target="_blank" :href="ins.content_url">
                  <div class="row w-100 sess mb-3">
                    <div class="col-lg-2 presimg">
                      <img
                        :src="
                          ins.presenter != null
                            ? ins.presenter.avatar_urls.large
                            : ''
                        "
                        class="insimg"
                      />
                    </div>
                    <div class="col-lg-10 dayinfo daysess">
                      <div class="row">
                        <div class="col-lg-7 pl-0">
                          <p class="mb-0" v-if="ins.title != null">
                            {{ ins.title }}
                          </p>
                          <p class="mb-0 presn" v-if="ins.presenter != null">
                            {{ ins.presenter.name }}
                          </p>
                        </div>
                        <div class="col-lg-2 p-0">
                          <p class="mb-0">
                            {{ ins.start_at.format("h:mma z") }}
                            <br />
                            <span>Start Time</span>
                          </p>
                        </div>
                        <div class="col-lg-3 p-0 text-right">
                          <a
                            target="_blank"
                            :href="ins.content_url"
                            onclick="   ga(
                            'send',
                            'Click Live Classes',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );"
                          >
                            <span class="jclass"
                              >Join Class <i class="far fa-play-circle ml-2"></i
                            ></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 text-right jmob">
                      <a
                        target="_blank"
                        :href="ins.content_url"
                        onclick="   ga(
                            'send',
                            'Click Live Classes',
                            [Button],
                            [Click],
                            [ins.title],
                            [Saved]
                          );"
                      >
                        <span class="jclass"
                          >Join Class <i class="far fa-play-circle ml-2"></i
                        ></span>
                      </a>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <h4>Here is Today’s WellFit Affirmation</h4>
        <div class="affblk mb-5 mt-3" v-if="settings.affirmation != null">
          {{ settings.affirmation }}
        </div>
        <a href="/WELLFIT WEEKLY HABIT TRACKER.pdf" target="_blank">
          <div class="tracker"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
    function groupbyDay(array, key) {
        const result = {};
        array.forEach((item) => {
            if (!result[item[key]]) {
                result[item[key]] = [];
            }
            result[item[key]].push(item);
        });
        // console.log(result)
        return result;
    }

    // import custom from '../../javascript/custom'
    import UserService from "../../../services/userService";
    import axios from "axios";
    import router from "@/router";
    import moment from "moment";
    axios.defaults.timeout = 40000;
    export default {
        name: "WeeklySchedulePage",
        data() {
            return {
                week: [],
                settings: {},
                now: moment().tz(moment.tz.guess(true)),
                temp: {
                    old_unique_date: null,
                },
            };
        },
        created() {
            this.id = this.$session.get("user").id;
        },
        mounted() {
            if (this.$session.get("subscription") == true) {
                this.getWeek();
                this.getSettings();
            } else {
                router.push({
                    path: "/customer/plans",
                });
            }
        },
        computed: {
            days() {
                return groupbyDay(this.week, "start_day");
            },
        },
        components: {},
        watch: {},
        methods: {
            async getSettings() {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.getSettings();
                    if (response.data.success == true) {
                        this.settings = response.data.data.settings;
                    } else {
                        //console.log("error");
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
            async getWeek() {
                var start = moment()
                    .tz("America/New_York")
                    .subtract(2, "hours")
                    .format("YYYY-MM-DD HH:mm:ss");
                var end = moment()
                    .tz("America/New_York")
                    .add(6, "day")
                    .format("YYYY-MM-DD");
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + this.$session.get("token");
                try {
                    const response = await UserService.weekSessions(start, end, "");
                    if (response.data.success == true) {
                        this.week = response.data.data;
                        for (var dy = 0; dy < this.week.length; dy++) {
                            this.week[dy].start_at = moment(this.week[dy].start_at).tz(
                                moment.tz.guess(true)
                            );
                            var year = new Date(this.week[dy].start_at).getFullYear();
                            this.week[dy]["start_day"] =
                                String(this.week[dy].start_at._d).split(year)[0] + year;
                        }
                    }
                } catch (err) {
                    //console.log(err);
                }
            },
        },
    };
</script>